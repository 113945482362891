const TEMP_LINK = "/"

const links = {
  frequentlyAskedQuestions: "/faq/",
  login: process.env.GATSBY_LOGIN_URL ?? TEMP_LINK,
  requestInvite: "/request-invite/",
  terms:
    "https://metagaragetermsandpolicies.notion.site/TERMS-OF-USE-bcbb523b098f44aeb0acd74ef8f052a7?pvs=4",
  privacy:
    "https://metagaragetermsandpolicies.notion.site/PRIVACY-POLICY-2c8ce89479d84719b6f3aa52b2eda7d9?pvs=4",
  forgotPassword: TEMP_LINK,
  disclosure:
    "https://metagaragetermsandpolicies.notion.site/IMPORTANT-DISCLOSURE-48e20fbaa0dc419e99efb7b83a1e07d9?pvs=4",
}

export default links
