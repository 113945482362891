import { gsap } from "gsap"
import { ScrollSmoother } from "gsap/ScrollSmoother"
import JustLogoSVG from "images/global/metagarageLogo.svg"
import WordmarkSVG from "images/global/metagarageLogoWordmark.svg"
import { fmobile, fresponsive } from "library/fullyResponsive"
import UniversalLink from "library/Loader/UniversalLink"
import useAnimation from "library/useAnimation"
import useMedia from "library/useMedia"
import { useState } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"
import links from "utils/links"

import PrimaryButton from "../buttons/Primary"
import MobileDialog from "./MobileDialog"

export default function Header() {
  const showDialog = useMedia(false, false, false, true)
  const [wrapper, setWrapper] = useState<HTMLElement | null>(null)

  useAnimation(() => {
    if (showDialog) return
    const showAnim = gsap
      .from([wrapper, ".menu-popover-content"], {
        yPercent: -150,
        paused: true,
      })
      .progress(1)

    const show = () => showAnim.play()
    const goAway = () => showAnim.reverse()

    const onScroll = () => {
      const scrollSpeed = ScrollSmoother.get()?.getVelocity() ?? 0
      if (window.scrollY < 100) show()
      else if (scrollSpeed > 0) goAway()
      else show()
    }
    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [wrapper, showDialog])

  return showDialog ? (
    <Wrapper ref={setWrapper}>
      <UniversalLink to="/">
        <JustLogo src={JustLogoSVG} alt="Metagarage logo" />
      </UniversalLink>
      <Spacer />
      <MobileDialog />
    </Wrapper>
  ) : (
    <Wrapper ref={setWrapper}>
      <UniversalLink to="/">
        <Wordmark src={WordmarkSVG} alt="Metagarage logo" />
      </UniversalLink>
      <Spacer />
      <HeaderLink to={links.frequentlyAskedQuestions}>FAQs</HeaderLink>
      <HeaderLink to={links.login}>Login</HeaderLink>
      <PrimaryButton to={links.requestInvite}>Request Invite</PrimaryButton>
    </Wrapper>
  )
}

const Wrapper = styled.header`
  background-color: ${colors.charcoal};
  display: flex;
  align-items: center;
  ${fresponsive(css`
    gap: 50px;
    padding: 20px 50px;
  `)}
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 30;

  ::before {
    content: "";
    position: absolute;
    top: calc(100% + 5.37px);
    left: 0;
    right: 0;
    height: 4.72px;
    background-color: ${colors.charcoal};
  }

  ::after {
    content: "";
    position: absolute;
    top: calc(100% + 15.46px);
    left: 0;
    right: 0;
    height: 2.36px;
    background-color: ${colors.charcoal};
  }

  ${fmobile(css`
    padding: 20px 15px;
  `)}
`

const JustLogo = styled.img`
  ${fresponsive(css`
    height: 25px;
    position: relative;
    z-index: 2;
  `)}
`

const Spacer = styled.div`
  flex-grow: 1;
`

const Wordmark = styled.img`
  ${fresponsive(css`
    height: 25px;
  `)}
`

const HeaderLink = styled(UniversalLink)`
  color: white;
  text-decoration: none;
  ${textStyles.bodyRegular};
`
