import gsap from "gsap"
import { ScrollSmoother } from "gsap/ScrollSmoother"
import { ReactComponent as LogoSVG } from "images/global/metagarageLogo.svg"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import getMedia from "library/getMedia"
import loader from "library/Loader"
import useAnimation from "library/useAnimation"
import { useEffect, useRef, useState } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

const ease = "0.6, 0, 0.1, 0.9"

export default function Preloader() {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [progress, setProgress] = useState(0)

  const animateOut = () => {
    ScrollSmoother.get()?.scrollTop(0)
    ScrollSmoother.get()?.refresh()
    ScrollSmoother.get()?.paused(false)

    const tl = gsap.timeline({
      defaults: {
        ease,
      },
      onStart: () => {
        ScrollSmoother.get()?.scrollTop(0)
      },
      onComplete: () => {
        ScrollSmoother.get()?.paused(false)
        gsap.set(wrapperRef.current, {
          display: "none",
        })
      },
    })

    tl.to(
      ".bars",
      {
        height: "100%",
        backgroundColor: colors.charcoal,
      },
      0
    )

    tl.to(
      ".logo",
      {
        scale: 1,
      },
      1
    )

    tl.to(
      ".logo",
      {
        scale: () => getMedia(60, 60, 60, 100),
        ease: "power2.in",
      },
      2
    )

    tl.set([".bars", ".bar", ".percentage"], {
      backgroundColor: colors.offWhite,
      color: colors.offWhite,
    })

    tl.to(
      wrapperRef.current,
      {
        opacity: 0,
      },
      3
    )
  }

  useAnimation(() => {
    ScrollSmoother.get()?.scrollTop(0)
    ScrollSmoother.get()?.paused(true)

    gsap.to(".bar", {
      width: `${progress}%`,
      ease: "power1.out",
      overwrite: true,
      stagger: 0.05,
      onComplete: () => {
        setTimeout(() => {
          if (progress === 100) {
            animateOut()
          }
        }, 1000)
      },
    })
  }, [progress])

  useEffect(() => {
    const updateProgress = (newProgress: number) => {
      setProgress(newProgress)
    }

    loader.addEventListener("progressUpdated", updateProgress)

    return () => {
      loader.removeEventListener("progressUpdated", updateProgress)
    }
  }, [])

  return (
    <Wrapper ref={wrapperRef}>
      <Bars className="bars">
        <Bar className="bar" />
        <Bar className="bar" />
        <Bar className="bar" />
      </Bars>
      <Percentage className="percentage">{Math.round(progress)}%</Percentage>
      <Logo className="logo" />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${colors.lightGray};
  z-index: 100;
  pointer-events: none;
  ${textStyles.h1}
`

const Bars = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;

  ${fresponsive(css`
    gap: 5px;
  `)}
`

const Bar = styled.div`
  background-color: ${colors.charcoal};
  width: 0%;

  ${fresponsive(css`
    height: 10px;
  `)}
`

const Percentage = styled.span`
  position: absolute;
  text-align: center;
  ${textStyles.mobileH2}

  ${fresponsive(css`
    bottom: 50px;
    left: 50px;
  `)}

  ${fmobile(css`
    left: 15px;
    bottom: 30px;
  `)}
`

const Logo = styled(LogoSVG)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  height: auto;
  transform-origin: 50% 42%;

  ${fresponsive(css`
    width: 703px;
  `)}

  ${ftablet(css`
    width: 527px;
  `)}

  ${fmobile(css`
    width: 263.5px;
  `)}
`
