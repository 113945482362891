import { ReactComponent as WordmarkSVG } from "images/global/loneWordmark.svg"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import UniversalLink from "library/Loader/UniversalLink"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"
import links from "utils/links"

import PrimaryButton from "./buttons/Primary"

const LegalText = (
  <>
    None of the information on our Site should be construed as investment
    advice. The information contained on the Site has been prepared by
    MetaGarage without reference to any particular user&apos;s investment
    requirements or financial situation. Potential investors are encouraged to
    consult with professional tax, legal, and financial advisors before making
    any investment into a MetaGarage offering. Read the{" "}
    <UniversalLink to={links.disclosure}>disclosure document</UniversalLink> and
    &nbsp;<UniversalLink to={links.terms}>terms</UniversalLink> and &nbsp;
    <UniversalLink to={links.privacy}>privacy policies</UniversalLink>
    &nbsp;for additional information.
  </>
)

export default function Footer() {
  return (
    <Wrapper>
      <TopRow>
        <UniversalLink to="/">
          <Wordmark />
        </UniversalLink>
        <PrimaryButton to={links.requestInvite}>Request Invite</PrimaryButton>
        <FooterLinks>
          <FooterLink to={links.terms}>Terms</FooterLink>
          <FooterLink to={links.privacy}>Privacy</FooterLink>
          <FooterLink to={links.disclosure}>Disclosure</FooterLink>
          <FooterLink to={links.frequentlyAskedQuestions}>FAQs</FooterLink>
          <FooterLink to={links.login}>Login</FooterLink>
        </FooterLinks>
      </TopRow>
      <Legal>{LegalText}</Legal>
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  background-color: ${colors.charcoal};
  display: grid;
  position: relative;

  ${fresponsive(css`
    gap: 100px;
    padding: 60px 50px 50px;
  `)}

  ${fmobile(css`
    padding: 30px 15px 50px;
    gap: 30px;
  `)}


  ::before {
    content: "";
    position: absolute;
    bottom: calc(100% + 5.37px);
    left: 0;
    right: 0;
    height: 5px;
    background-color: ${colors.charcoal};
  }

  ::after {
    content: "";
    position: absolute;
    bottom: calc(100% + 15.74px);
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${colors.charcoal};
  }
`

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${fmobile(css`
    flex-direction: column;
    gap: 30px;
  `)}
`

const Wordmark = styled(WordmarkSVG)`
  ${fresponsive(css`
    height: 45px;
  `)}
`

const FooterLinks = styled.div`
  ${fresponsive(css`
    display: flex;
    gap: 50px;
  `)}

  ${ftablet(css`
    gap: 32px;
  `)}
  ${fmobile(css`
    width: 345px;
    gap: 32px;
  `)}
`

const FooterLink = styled(UniversalLink)`
  ${textStyles.bodyRegular};
  color: ${colors.offWhite};

  ${fmobile(css`
    ${textStyles.bodySmall};
  `)}
`

const Legal = styled.div`
  ${textStyles.bodySmall};
  color: ${colors.offWhite};
  white-space: pre-wrap;

  a {
    text-decoration: underline;
  }

  ${fmobile(css`
    ${textStyles.bodyDisclaimer};
    text-align: center;
  `)}
`
