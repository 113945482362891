import { fresponsive } from "library/fullyResponsive"
import { css } from "styled-components"

import media from "./media"

const textStyles = {
  h1: fresponsive(css`
    /* Heading/H1 */
    font-family: Avantt, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 240px;
    line-height: 80%;
    letter-spacing: -0.04em;

    /* Mobile/H1 */
    ${media.mobile} {
      font-size: 60px;
      line-height: 90%;
      text-align: center;
      letter-spacing: -0.02em;
    }
  `),
  h2: fresponsive(css`
    /* Heading/H2 */

    font-family: Avantt, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 180px;
    line-height: 85%;
    letter-spacing: -0.04em;
  `),
  h3: fresponsive(css`
    /* Heading/H3 */

    font-family: Avantt, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 96px;
    line-height: 90%;
    letter-spacing: -0.03em;
  `),
  h4: fresponsive(css`
    /* Heading/H4 */

    font-family: Avantt, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 100%;
    letter-spacing: -0.02em;
  `),
  h5: fresponsive(css`
    /* Heading/H5 */

    font-family: Avantt, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 90%;
    letter-spacing: -0.02em;
  `),
  h6: fresponsive(css`
    font-family: Avantt, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 96px;
    line-height: 90%;
    letter-spacing: -0.03em;
  `),

  tabletH2: fresponsive(css`
    font-family: Avantt, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 72px;
    line-height: 85%;
    letter-spacing: -1.44px;
  `),

  tabletH3: fresponsive(css`
    font-family: Avantt, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 90%;
    letter-spacing: -0.02em;
  `),
  mobileH1: fresponsive(css`
    font-family: Avantt, sans-serif;
    font-size: 60px;
    font-weight: 800;
    line-height: 90%;
    letter-spacing: -1.2px;
  `),
  mobileH2: fresponsive(css`
    font-family: Avantt, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 90%;
    letter-spacing: -0.02em;
  `),
  bodyLarge: fresponsive(css`
    /* Body/Large */

    font-family: Avantt, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: -0.02em;
  `),
  bodyRegular: fresponsive(css`
    /* Body/Regular */

    font-family: Avantt, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: -0.01em;
  `),
  bodySmall: fresponsive(css`
    /* Body/Small */

    font-family: Avantt, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
  `),
  bodyDisclaimer: fresponsive(css`
    /* Body/Disclaimer */

    font-family: Avantt, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 120%;
  `),
  buttonLarge: fresponsive(css`
    /* Button/Large */

    font-family: Avantt, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: -0.02em;
  `),
  buttonSmall: fresponsive(css`
    /* Button/Small */

    font-family: Avantt, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 120%;
  `),
}

export const strokeText = css`
  /* styled doesn't prefix this property yet */
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-size: 100%;
  background-clip: text;
  -webkit-text-stroke-width: 0.07vw;
`

export default textStyles
