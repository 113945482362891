import * as Popover from "@radix-ui/react-popover"
import * as VisuallyHidden from "@radix-ui/react-visually-hidden"
import PrimaryButton from "components/buttons/Primary"
import { gsap } from "gsap"
import { fresponsive } from "library/fullyResponsive"
import loader from "library/Loader"
import UniversalLink from "library/Loader/UniversalLink"
import useAnimation from "library/useAnimation"
import { useEffect, useRef, useState } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"
import links from "utils/links"

export default function MobileDialog() {
  const [open, setOpen] = useState(false)
  const menuContent = useRef<HTMLDivElement>(null)

  const onOpenOrClose = (newOpen: boolean) => setOpen(newOpen)

  useEffect(() => {
    const onTransition = () => setOpen(false)
    loader.addEventListener("anyStart", onTransition)
    loader.addEventListener("scrollToTop", onTransition)
    return () => {
      loader.removeEventListener("anyStart", onTransition)
      loader.removeEventListener("scrollToTop", onTransition)
    }
  }, [])

  useAnimation(
    () => {
      const duration = 0.5
      gsap.to(menuContent.current, {
        y: open ? 0 : "-100%",
        duration,
        ease: "power1.inOut",
      })
      gsap.set(menuContent.current, {
        autoAlpha: open ? 1 : 0,
        delay: open ? 0 : duration,
      })
    },
    [open],
    {
      kill: true,
    }
  )

  return (
    <Popover.Root onOpenChange={onOpenOrClose} open={open}>
      <MenuButton aria-label="menu-toggle">
        <Bar isCross={open} />
        <Bar isCross={open} />
        <Bar isCross={open} />
      </MenuButton>
      <Anchor />
      <MenuContent
        ref={menuContent}
        forceMount
        className="menu-popover-content"
      >
        <VisuallyHidden.Root>Navigation Menu</VisuallyHidden.Root>
        <CloseButton>Close Menu</CloseButton>
        <TopLinks role="presentation" onClick={() => setOpen(false)}>
          <MainLink to="/">Home</MainLink>
          <MainLink to={links.frequentlyAskedQuestions}>FAQs</MainLink>
          <MainLink to={links.login}>Login</MainLink>
          <PrimaryButton to={links.requestInvite}>Request Invite</PrimaryButton>
        </TopLinks>
        <BottomLinks role="presentation" onClick={() => setOpen(false)}>
          <BottomLink to={links.terms}>Terms</BottomLink>
          <BottomLink to={links.privacy}>Privacy</BottomLink>
          <BottomLink to={links.disclosure}>Disclosure</BottomLink>
        </BottomLinks>
      </MenuContent>
    </Popover.Root>
  )
}

const MenuButton = styled(Popover.Trigger)`
  ${fresponsive(css`
    width: 40px;
    height: 40px;
    background-color: ${colors.blue};
    border-radius: 99vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3px;
    position: relative;
    z-index: 2;
  `)}
`

/**
 * positioned directly on top of the menu button
 */

const Bar = styled.div<{
  isCross: boolean
}>`
  ${({ isCross }) =>
    fresponsive(css`
      width: 16px;
      height: 2px;
      background: ${colors.offWhite};
      transition: transform 0.2s ease-in-out;

      :nth-child(1) {
        transform: ${isCross ? "translateY(5px) rotate(-45deg)" : "rotate(0)"};
      }

      :nth-child(2) {
        transform: ${isCross ? "rotate(45deg)" : "rotate(0)"};
      }

      :nth-child(3) {
        transform: ${isCross ? "translateY(-5px) rotate(-45deg)" : "rotate(0)"};
      }
    `)}
`

const Anchor = styled(Popover.Anchor)`
  position: fixed;
  top: 0;
  left: 0;
`

const MenuContent = styled(Popover.Content)`
  position: fixed;
  left: 0;
  width: 100vw;
  z-index: 1;
  background-color: ${colors.charcoal};
  color: ${colors.offWhite};
  display: grid;
  grid-template-rows: 1fr auto;
  place-items: center;
  visibility: hidden;
  ${fresponsive(css`
    top: 80px;
    height: calc(100vh - 80px);
  `)}

  ::before {
    content: "";
    position: absolute;
    top: calc(100% + 5.37px);
    left: 0;
    right: 0;
    height: 4.72px;
    background-color: ${colors.charcoal};
  }

  ::after {
    content: "";
    position: absolute;
    top: calc(100% + 15.46px);
    left: 0;
    right: 0;
    height: 2.36px;
    background-color: ${colors.charcoal};
  }
`

const CloseButton = styled(Popover.Close)`
  ${fresponsive(css`
    position: absolute;
    z-index: 1;
    top: -60px;
    right: 15px;
    width: 40px;
    height: 40px;
    background-color: ${colors.yellow};
    background-color: transparent;
    color: transparent;
    border-radius: 99vw;
  `)}

  ${textStyles.bodyRegular}
`

const TopLinks = styled.div`
  display: grid;
  justify-content: center;
  ${fresponsive(css`
    gap: 40px;
  `)}
`

const MainLink = styled(UniversalLink)`
  ${textStyles.h5};
  text-align: center;
`

const BottomLinks = styled.div`
  display: flex;
  justify-content: center;
  ${fresponsive(css`
    gap: 50px;
    margin: 50px 50px 80px;
  `)}
`

const BottomLink = styled(UniversalLink)`
  ${textStyles.bodyRegular}
`
