import Footer from "components/Footer"
import Header from "components/Header"
import Preloader from "components/Preloader"
import { useBackButton } from "library/Loader/TransitionUtils"
import { useTrackPageReady } from "library/pageReady"
import Scroll from "library/Scroll"
import useCSSHeightVariables from "library/useCssHeightVariables"
import useTrackFrameTime from "library/useTrackFrameTime"
import styled, { createGlobalStyle, css } from "styled-components"
import colors from "styles/colors"

import PageTransition from "./PageTransition"

interface LayoutProps {
  children: React.ReactNode
}

export default function Layout({ children }: LayoutProps) {
  useTrackPageReady()
  useBackButton()
  useCSSHeightVariables()
  useTrackFrameTime()

  return (
    <>
      <Preloader />
      <PageTransition />
      <GlobalStyle />
      <Header />
      <Scroll>
        <Main>{children}</Main>
        <Footer />
      </Scroll>
    </>
  )
}

const Main = styled.main`
  overflow-x: hidden;
  padding-top: 107px;
  width: 100vw;
`

const globalCss = css`
  body {
    color: ${colors.charcoal};
    background-color: ${colors.offWhite};
    overscroll-behavior: none;
  }

  * {
    /* need this so that fonts match figma */
    text-rendering: geometricprecision;
  }

  /* fixes for visbug */
  vis-bug {
    position: fixed;
    z-index: var(--layer-1);
  }

  visbug-metatip,
  visbug-handles,
  visbug-ally {
    position: absolute;
    z-index: var(--layer-top);
  }

  /** restore default focus states for elements that need them */
  *:focus-visible {
    outline: 2px solid ${colors.blue}88;
  }
`

const GlobalStyle = createGlobalStyle`${globalCss}`
