import { ReactComponent as ArrowSVG } from "images/global/primaryArrow.svg"
import { fmobile, fresponsive } from "library/fullyResponsive"
import UniversalLink, { UniversalLinkProps } from "library/Loader/UniversalLink"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

type PrimaryButtonProps = UniversalLinkProps & {
  oversize?: boolean
}

export default function PrimaryButton({
  oversize = false,
  ...props
}: PrimaryButtonProps) {
  return (
    <Wrapper {...props} $oversize={oversize}>
      <Arrow $oversize={oversize} />
      {props.children}
      <Arrow $oversize={oversize} />
    </Wrapper>
  )
}

const Wrapper = styled(UniversalLink)<{ $oversize: boolean }>`
  ${textStyles.bodyRegular};
  ${({ $oversize }) => $oversize && textStyles.buttonLarge};
  background: ${colors.blue};
  display: grid;
  width: fit-content;
  grid-template-columns: auto auto auto;
  align-items: center;
  justify-content: center;
  border-radius: 99vw;
  color: ${colors.offWhite};
  transition: 0.05s ease-in-out;
  transition-property: background;

  ${({ $oversize: oversize }) =>
    fresponsive(css`
      padding: 10px;
      gap: 8px;
      min-height: 50px; /* button height plus padding */

      ${oversize &&
      css`
        padding: 20px;
        gap: 16px;
        min-height: 100px; /* button height plus padding */
      `}
    `)}

  ${fmobile(css`
    width: 100%;
  `)}

  :active {
    background: ${colors.blue80};
  }
`

const Arrow = styled(ArrowSVG)<{ $oversize: boolean }>`
  ${({ $oversize }) =>
    fresponsive(css`
      --button-size: ${$oversize ? "60px" : "30px"};

      background: ${colors.offWhite};
      display: block;
      border-radius: 50%;
      transition: 0.2s ease-in-out;
      transition-property: width, transform, height;
      width: 0;
      height: 0;

      ${Wrapper}:hover &:last-child, ${Wrapper}:not(:hover) &:first-child {
        width: var(--button-size);
        height: var(--button-size);
      }

      ${Wrapper}:not(:hover) &:first-child {
        transform: rotate(0);
      }

      ${Wrapper}:hover &:last-child {
        transform: rotate(0);
      }

      path {
        stroke: ${colors.blue};
      }
    `)}
`
