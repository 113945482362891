import gsap from "gsap"
import { fresponsive } from "library/fullyResponsive"
import {
  registerTransition,
  unregisterTransition,
} from "library/Loader/TransitionUtils"
import { useEffect, useRef } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

const ease = "0.6, 0, 0.1, 0.9"

export default function GreenTransition() {
  const wrapperRef = useRef<HTMLDivElement>(null)

  const slideIn = () => {
    const tl = gsap.timeline()

    tl.to(
      wrapperRef.current,
      {
        autoAlpha: 1,
        duration: 0.3,
        ease,
      },
      0
    ).to(
      ".transition-bar",
      {
        stagger: 0.05,
        duration: 0.4,
        width: "100%",
        ease,
      },
      0.3
    )
  }

  const slideOut = () => {
    gsap.to(wrapperRef.current, {
      duration: 0.4,
      yPercent: -100,
      delay: 0.2,
    })
  }

  useEffect(() => {
    registerTransition("slide", {
      in: slideIn,
      out: slideOut,
      inDuration: 0.7,
      outDuration: 0.6,
    })

    return () => {
      unregisterTransition("slide", [slideIn, slideOut])
    }
  }, [])

  return (
    <Wrapper ref={wrapperRef}>
      <Bars className="transition-bars">
        <Bar className="transition-bar" />
        <Bar className="transition-bar" />
        <Bar className="transition-bar" />
      </Bars>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${colors.lightGray};
  z-index: 100;
  opacity: 0;
  pointer-events: none;
  display: grid;
  place-items: center;

  ${textStyles.h1}
`
const Bars = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;

  ${fresponsive(css`
    gap: 5px;
  `)}
`

const Bar = styled.div`
  background-color: ${colors.charcoal};
  width: 0%;

  ${fresponsive(css`
    height: 10px;
  `)}
`
