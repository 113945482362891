const colors = {
  // neutrals
  offWhite: "#f2eee1",
  charcoal: "#1a1917",
  darkerGray: "#4d4a45",
  darkGray: "#807c73",
  mediumGray: "#b2aea0",
  lightGray: "#e5e0cf",

  // primary blue
  blue: "#0b8be5",
  blue80: "#096cb2",
  blue60: "#064d80",

  // secondary colors
  red: "#e53322",
  yellow: "#f2b600",

  // tertiary colors
  darkBlue: "#262833",
  darkRed: "#332721",
  darkGreen: "#404029",

  // positive green
  green: "#08a05c",
} as const

export default colors
